import React, {useState, useEffect, useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso, ArticleFilterSearch2,FormatedPrice,ColumDataTableReorganize,ToastPopUp,CarouselImage,articleColumnGenerate,customFieldsColumnGenerate,priceSuppColumnGenerate,enumereColumnGenerate,articleEditionDatatable,articleDataGenerator} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type} from 'react-bootstrap-table2-editor';
import axios from 'axios'
import { articleHardData } from 'app/articleHardData';
import swal from "sweetalert2";
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';
import { Modal } from "react-bootstrap";
import {articleListErp} from 'app/columnsArticleListeDataTable'
import { IsAuthContext } from 'app/App';

const useLoadInit = (props) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [articles,setArticles]=useState([])
    const [sizeDatas,setSizeDatas]=useState(0)
    const [liaisons,setLiaisons]=useState([])
    const [customInterface, setCustomInterface] = useState({})
    const [customFields,setCustomFields]=useState([])

    useEffect(function () {
        (async function() {
            setCustomInterface({})
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/list/50/1",{havestock:'oui'}
            ).then((response) => {
                setLoading(false)
                setArticles(response.data.datas)
                setSizeDatas(response.data.count)
                let allFilter = {};
                if(response.data.custom_interface.user_conf){
                    response.data.custom_interface.user_conf.details.map(value=>
                        allFilter[value]=[]
                    )
                }else if(response.data.custom_interface.default_conf){
                    response.data.custom_interface.default_conf.details.map(value=>
                        allFilter[value]=[]
                    )
                }

                props.setSendData(allFilter)
                setCustomInterface(response.data.custom_interface)
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        })()
    }, [props.reload])
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/liaison"
            ).then((response) => {
                setLiaisons(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        })()
    }, [])
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/article"
            ).then((response) => {
                setCustomFields(response.data.datas)
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        })()
    }, [])
    return [loading,articles,setArticles,sizeDatas,setSizeDatas,liaisons,customInterface,setCustomInterface,customFields]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columnShow.length)
    }, [window.screen.width,props.columnShow.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            onTableChange={ props.handleTableChange }
            noDataIndication={t('tableNoData')}
            defaultSortDirection={props.defaultSortDirection}
            cellEdit={ cellEditFactory({ 
                mode: 'dbclick', 
                blurToSave: true,
            }) }
            hover
            condensed
            responsive
        />
    );
};

const ArticleListFiltre = (props) => {
    const t = useTranslate();
    const tableName = "baseArticleListFiltre" 
    const [perPage,setPerPage] = useState(50)
    const [currentPage,setCurrentPage] = useState(1)
    const [loading,articles,setArticles,sizeDatas,setSizeDatas,liaisons,customInterface,setCustomInterface,customFields] = useLoadInit(props)
    const [showModalCustomFiltre, setShowModalCustomFiltre] = useState(false);
    const [cookies, setCookie] = useCookies(['article_field_inventaire', 'inventaire_article_list_filtre']);
    const [clearTable,setClearTable]=useState(false) 
    const [toast,setToast]=useState(false) 
    const { authParam } = useContext(IsAuthContext);
    const [showAccordion,setShowAccordion]=useState(false) 
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let cookieLecture = ['internalid','name','reference','price','price_b2b'];
    if(cookies.article_field_inventaire){
        let columnShowVar = cookies.article_field_inventaire;
        cookieLecture = columnShowVar.split(',')
    }
    const [columnShow,setColumnShow] = useState(cookieLecture)

    //Pour le carousel
    const [showCarousel,setShowCarousel]=useState(false) 
    const [modalItemName,setModalItemName]=useState('') 
    const [modalImage,setModalImage]=useState('') 

    const openModalCarousel = (image,itemName) =>{
        setModalImage(image)
        setModalItemName(itemName)
        setShowCarousel(true)
    }

    const closeModalCarousel = () =>{
        setModalImage('')
        setModalItemName('')
        setShowCarousel(false)
    }

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: sizeDatas
    };

    let datas = new Array
    articles.forEach((value,ind)=>{
        let obj = {
            devise:(value.devise_achat!=null) ? value.devise_achat : '',//digit={(value.devise_achat!='XPF') ? 2 : 0}
            id: value.id,
            buy_price: (value.devise_achat && value.buy_price) ? <FormatedPrice langRegion={'fr-FR'} devise={value.devise_achat} digit={(Number.isInteger(+value.buy_price)) ? 0 : 2} priceToFormat={value.buy_price} /> : '0',
            index: ind + 1,
        }
        articleDataGenerator(obj,value,t,openModalCarousel)
        for (let i=0; i<props.maxConditionnement; i++){
            let numeroEnumere = i+1
            obj['name_'+numeroEnumere]= (value.hasOwnProperty('name_'+numeroEnumere) && value['name_'+numeroEnumere]!=null) ? value['name_'+numeroEnumere] : ''
            obj['reference_'+numeroEnumere]= (value.hasOwnProperty('reference_'+numeroEnumere) && value['reference_'+numeroEnumere]!=null) ? value['reference_'+numeroEnumere] : ''
            obj['quantity_'+numeroEnumere]= (value.hasOwnProperty('quantity_'+numeroEnumere) && value['quantity_'+numeroEnumere]!=null) ? value['quantity_'+numeroEnumere] : ''
        }
        props.suppPrice.map((item,index)=>{
            let numero = index+1
            let margePrixSupp = (value.hasOwnProperty('margeN_'+item['designation_'+numero]) && value['margeN_'+item['designation_'+numero]]!=null && value['margeN_'+item['designation_'+numero]]!=0) ? value['margeN_'+item['designation_'+numero]] : 0

            let margePercentPrixSupp = (value.hasOwnProperty('margeP_'+item['designation_'+numero]) && value['margeP_'+item['designation_'+numero]]!=null && value['margeP_'+item['designation_'+numero]]!=0) ? value['margeP_'+item['designation_'+numero]] : 0


            obj['price_'+item['designation_'+numero]]= (value.hasOwnProperty('price_'+item['designation_'+numero]) && value['price_'+item['designation_'+numero]]!==null && value['price_'+item['designation_'+numero]]!=='') ? value['price_'+item['designation_'+numero]] : ''

            obj['price_'+item['designation_'+numero]+'_ttc']= (value.hasOwnProperty('price_'+item['designation_'+numero]+'_ttc') && value['price_'+item['designation_'+numero]+'_ttc']!==null && value['price_'+item['designation_'+numero]+'_ttc']!==null) ? value['price_'+item['designation_'+numero]+'_ttc'] : ''

            obj['margeN_'+item['designation_'+numero]]= margePrixSupp

            obj['margeP_'+item['designation_'+numero]]= margePercentPrixSupp
        })
        datas.push(obj)
    })

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columnsInit = [
        {
            dataField: "internalid",
            text: t('internalId'),
            editable: false,
            sort: true,
            headerSortingStyle,
            hidden: (columnShow.includes("internalid")) ? false : true
        },
    ];

    if(props.module=='erp'){
        columnsInit.push(
            {
                dataField: "devise_achat",
                text: t('devise_achat'),
                editable: false,
                sort: true,
                headerSortingStyle,
                hidden: (columnShow.includes("devise_achat")) ? false : true
            },
            {
                dataField: "buy_price",
                text: t('buy_price'),
                editable: false,
                sort: true,
                headerSortingStyle,
                hidden: (columnShow.includes("buy_price")) ? false : true
            },
        )
    }

    let columns=[
        ...columnsInit,
        ...articleColumnGenerate(articleListErp,columnShow,headerSortingStyle,articleEditionDatatable,props.optionsDataTable,props.ourOptionsDataTable,t,authParam),
        ...enumereColumnGenerate(props.maxConditionnement,columnShow,headerSortingStyle,articleEditionDatatable,t,authParam),
        ...priceSuppColumnGenerate(props.suppPrice,columnShow,headerSortingStyle,articleEditionDatatable,t,authParam),
        ...customFieldsColumnGenerate(props.customFields,columnShow,headerSortingStyle,articleEditionDatatable,t,authParam)
    ]

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.inventaire_article_list_filtre){
            let cookieLecture = cookies.inventaire_article_list_filtre.split(',')

            let already_done=[];
            cookieLecture.map(value=>{//on boucle sur toutes les colonnes existante sur le cookie de réorganisation 
                columns.map(item=>{//on vérifie dans l'array columns si les colonnes qu'il contient sont présent dans le cookie 
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle colonne à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    arrCol.push(item);
                }
            })
            columnCookie = arrCol

            if(arrCol.length !== cookieLecture.length){ 
                //Si des données articles ont été ajoutées ou si une configuration d'access à été faite pour rajouter ou retirer des colonnes, le cookie de réorganisation sera mis à jour 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("inventaire_article_list_filtre",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [])

    useEffect(function () {
        if(cookies.article_field_inventaire){
            let cookieColumnShow = cookies.article_field_inventaire.split(',')
            let columnsDatas = [] //contient toutes les dataFields de columns
            let arrColumnShow = [] //contient toutes les données sans doublons
            let arrColumnShowDoublon = [] //contient les doublons
            let arrColumnShowIntrus = [] // continent les fausse données

            columns.map(item=>{
                columnsDatas.push(item.dataField)
            })

            cookieColumnShow.map(value=>{
                //on vérifie si le cookie contien des doublons
                if(!arrColumnShow.includes(value)){
                    arrColumnShow.push(value)
                }else{
                    //Si value existe dejà c'est qu'il y a un doublon 
                    arrColumnShowDoublon.push(value)
                }
                //on vérifie si les données du cookies sont des données qui exisent dans le tableau columns
                if(!columnsDatas.includes(value)) arrColumnShowIntrus.push(value)
                
            })

            if(arrColumnShowDoublon.length!=0 || arrColumnShowIntrus.length!=0){ 
                //Si le cookie contient des doublons et des données qui n'existe pas en meme temps
                let uniformData = arrColumnShow.filter(value=> !arrColumnShowIntrus.includes(value))

                setCookie("article_field_inventaire",uniformData.join(),{ path: "/", expires: expire_date })
            }
        }
    }, [])

    const handleToggleColumns = (column,indexToEdit) => {
        if(column.toggle){
            var newColumnShow = columnShow.filter(value=>value!=column.dataField)

            //Modification pour la mise à jour des status hidden de chaque colonnes
            let dataColumn = columnOrganize.filter(value=>value.dataField!=column.dataField)
            let newData = column
            newData.hidden = true

            dataColumn.splice(indexToEdit,0, newData)

            setColumnOrganize(dataColumn)
            //End

            setColumnShow(newColumnShow)
        }
        else{
            var newColumnShow = [...columnShow,column.dataField]

            //Modification pour la mise à jour des status hidden de chaque colonnes
            let dataColumn = columnOrganize.filter(value=>value.dataField!=column.dataField)
            let newData = column
            newData.hidden = false

            dataColumn.splice(indexToEdit,0, newData)

            setColumnOrganize(dataColumn)
            //End

            setColumnShow(newColumnShow)
        }
        setCookie("article_field_inventaire",newColumnShow.join(),{ path: "/", expires: expire_date }) 
    }

    const handleTableChange = async (type, { page, sizePerPage, sortField, sortOrder }) => {
        let objFiltre 

        if(type=='sort'){
            objFiltre = {
                ...props.filtre,
                sortField:sortField,
                sortOrder:sortOrder
            }
            props.setFiltre(objFiltre)
            props.setSendData({
                ...props.sendData,
                sortField:sortField,
                sortOrder:sortOrder
            })
        }else{
            objFiltre = props.filtre
        }

        setPerPage(sizePerPage)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/list/"+sizePerPage+"/"+page,objFiltre
            ).then((response) => {
                setArticles(response.data.datas)
                setSizeDatas(response.data.count)
                setCurrentPage(page)
            }).catch((error) => {
                if(error=='end_session') return;
                swal.fire(t('unknowError'), "", "error");
            })
    }

    const CustomToggleAccordion = ({ children }) => {
        let hisClass = (showAccordion) ? "btn btn-sm btn-primary mb-3" : "btn btn-sm btn-outline-primary mb-3"
        
        return (
            <button
                type="button"
                className={hisClass}
                style={{marginRight:'10px'}}
                onClickCapture={()=>setShowAccordion(!showAccordion)}
            >
                {children}
            </button>
        );
    }
    
    return (
        <div className="row">
            <ToastPopUp 
                showToast={toast}
                setShowToast={setToast}
                message={t('favoriteSaved')}
            />
            <Modal show={showCarousel} onHide={()=>closeModalCarousel()}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalItemName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CarouselImage images={modalImage} />
                </Modal.Body>
            </Modal>
            <div className="col-12">
                <CardPerso header={t('items')}>
                    {(loading) ? 
                        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                    :
                        <small>
                            <ToolkitProvider
                            keyField="index"
                            data={ datas }
                            columns={ columnOrganize }
                            columnToggle
                            >
                            {(propst) => (
                                <>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="d-flex align-items-baseline">
                                                        <CustomToggleAccordion>{t('filtres')}</CustomToggleAccordion>
                                                        <ColumDataTableReorganize 
                                                            columnOrganize={columnOrganize}
                                                            setColumnOrganize={setColumnOrganize}
                                                            setCookie={setCookie}
                                                            cookieName='inventaire_article_list_filtre'
                                                            { ...propst.columnToggleProps } 
                                                            executWhenToggle={handleToggleColumns}
                                                            showColumnOrganize={showColumnOrganize}
                                                            setShowColumnOrganize={setShowColumnOrganize}
                                                            btnSwitch={true}//pour affiche ou non le btn swith
                                                            initSwith={false}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                                        />
                                                    </div>
                                                    {(showAccordion) &&
                                                    <ArticleFilterSearch2
                                                        liaisons={liaisons}
                                                        customFields={customFields} 
                                                        setDataTable={setArticles} 
                                                        setSizeDatas={setSizeDatas} 
                                                        setFiltre={props.setFiltre} 
                                                        perPage={perPage}
                                                        customInterface={customInterface}
                                                        setCustomInterface={setCustomInterface}
                                                        setReload={props.setReload}
                                                        sendData={props.sendData}
                                                        setSendData={props.setSendData}
                                                        setShowModalCustomFiltre={setShowModalCustomFiltre}
                                                        showModalCustomFiltre={showModalCustomFiltre}
                                                        initAllFilter={{}}
                                                        interface='filtreArticle' //Variable qui permet d'identifier l'url sur laquelle filtrer
                                                        hardData={articleHardData}
                                                        moduleName='items'
                                                        setCurrentPage={setCurrentPage}

                                                        from='inventaire'
                                                        setShowAddBtn={props.setShowAddBtn}
                                                        setClearTable={setClearTable}

                                                        tableName={tableName}
                                                        setToast={setToast}
                                                    >
                                                    </ArticleFilterSearch2>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {!clearTable &&
                                        <div className="position-relative">
                                            <CustomDataTable
                                                propst={propst}
                                                paginationOptions={paginationOptions}
                                                handleTableChange={handleTableChange}
                                                columnShow={columnShow}
                                                defaultSortDirection="asc"
                                            />
                                        </div>
                                    }
                                </>
                            )}
                            </ToolkitProvider>
                        </small>
                    }
                </CardPerso>
            </div>
        </div>
    );
}

export default ArticleListFiltre;
