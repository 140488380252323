export function espace_mille(nombre,digit){
    let number
    let prepend
    let append
    let numToConvert 

    if(digit==0 || nombre=='' || Number.isInteger(+nombre)){
        numToConvert = nombre
        append = ''
    }else{
        let initNum = Number(nombre).toFixed(digit)
        let nb = initNum.split('.')
        numToConvert = nb[0]
        append = '.'+nb[1]
    }

    if(numToConvert.toString().startsWith("-")){
        number = numToConvert.toString().replace('-','')
        prepend = '-'
    }else{
        number = numToConvert
        prepend = ''
    }

    if(number<=999) return prepend+number.toString()+append;
    if(number<=999999) return prepend+number.toString().slice(0,number.toString().length-3)+' '+number.toString().slice(-3)+append;
    if(number<=999999999) return prepend+number.toString().slice(0,number.toString().length-6)+' '+number.toString().slice(-6,-3)+' '+number.toString().slice(-3)+append;
}