import React from "react";
import { useTranslate } from 'react-redux-multilingual'
import { ControlAccess} from '@gull'
import { Modal,Tabs, Tab} from "react-bootstrap";
import EditPriceForm from './EditPriceForm'
import CancelDiscount from './CancelDiscount'

const EditPriceModal = (props) =>{
    const t =useTranslate()
   
    return(
        <Modal size="md" show={props.showEditPrice} onHide={() => props.closeModal('price')}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(props.restrictionModifPrix && !props.restrictionModifPrix.go) ? 
                    <ControlAccess section={props.section} restriction={props.restrictionModifPrix} setRestriction={props.setRestrictionModifPrix} moduleId={props.section.id} callback={props.callback} noMarge={true}/>
                :
                    <Tabs defaultActiveKey="percent" id="uncontrolled-tab-example" style={{marginTop:'1px'}}>
                        <Tab eventKey="percent" title={t('discountPercent')}>
                            <EditPriceForm 
                                module={props.module} 
                                isFor='percent' 
                                closeModal={props.closeModal}
                                cookies={props.cookies} 
                                item={props.item} 
                                setCurrentTicket={props.setCurrentTicket} 
                                setDisabledPay={props.setDisabledPay} 
                                itemsBilling={props.itemsBilling} 
                                setItemsBilling={props.setItemsBilling} 
                                clientDetail={props.clientDetail} 
                                id={props.id}
                                setAllInfos={props.setAllInfos}
                                groupId={props.groupId}
                                forGroup={props.forGroup}
                                affichageClient={props.affichageClient}
                                peripheriqueParam={props.peripheriqueParam}
                                authParam={props.authParam}
                            />
                        </Tab>
                        <Tab eventKey="net" title={t('discountNet')}>
                            <EditPriceForm 
                                module={props.module} 
                                isFor='net' 
                                closeModal={props.closeModal} 
                                cookies={props.cookies} 
                                item={props.item} 
                                setCurrentTicket={props.setCurrentTicket} 
                                setDisabledPay={props.setDisabledPay} 
                                itemsBilling={props.itemsBilling} 
                                setItemsBilling={props.setItemsBilling} 
                                clientDetail={props.clientDetail} 
                                id={props.id}
                                setAllInfos={props.setAllInfos}
                                groupId={props.groupId}
                                forGroup={props.forGroup}
                                affichageClient={props.affichageClient}
                                peripheriqueParam={props.peripheriqueParam}
                                authParam={props.authParam}
                            />
                        </Tab>
                        <Tab eventKey="modif" title={t('priceModification')}>
                            <EditPriceForm 
                                module={props.module} 
                                isFor='modif' 
                                closeModal={props.closeModal} 
                                cookies={props.cookies} 
                                item={props.item} 
                                setCurrentTicket={props.setCurrentTicket} 
                                setDisabledPay={props.setDisabledPay} 
                                itemsBilling={props.itemsBilling} 
                                setItemsBilling={props.setItemsBilling} 
                                clientDetail={props.clientDetail} 
                                id={props.id}
                                setAllInfos={props.setAllInfos}
                                groupId={props.groupId}
                                forGroup={props.forGroup}
                                affichageClient={props.affichageClient}
                                peripheriqueParam={props.peripheriqueParam}
                                authParam={props.authParam}
                            />
                        </Tab>
                        {((props.item && props.item.remise!=0) || props.forGroup) &&
                            <Tab eventKey="cancel" title={t('cancelDiscount')}>
                                <CancelDiscount
                                    id={props.id}
                                    cookies={props.cookies}
                                    item={props.item}
                                    closeModal={props.closeModal}
                                    setCurrentTicket={props.setCurrentTicket} 
                                    setItemsBilling={props.setItemsBilling}  
                                    setAllInfos={props.setAllInfos}
                                    groupId={props.groupId}
                                    forGroup={props.forGroup}
                                    affichageClient={props.affichageClient}
                                    peripheriqueParam={props.peripheriqueParam}
                                    module={props.module}
                                />
                            </Tab>
                        }
                    </Tabs>
                }
            </Modal.Body>
        </Modal>
    )
}

export default EditPriceModal;