import React, {useState, useEffect} from 'react';
import { useParams} from "react-router-dom";
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual'
import { HeadInfos, Loading, CardPerso, ColumDataTableReorganize} from "@gull";
import SweetAlert from "sweetalert2-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';

const useLoadHistoriqueDetail = () => {
    const { id } = useParams();
    const [historiqueDetail, setHistoriqueDetail] = useState(false);
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)


    useEffect(function () {
        
        (async function() {
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/historique/edit/"+id
            ).then((response) => {
                setLoading(false)
                setHistoriqueDetail(response.data)
            }).catch((error) => {
                if(error=='end_session') return;
               setLoading(false)
               setError(true)
            })
        })()
    }, [id])
    
    return [loading,error,historiqueDetail]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propts.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSorted={props.defaultSorted}
            defaultSortDirection={props.defaultSortDirection}
            hover
            condensed
            responsive
        />
    );
};

const HistoriqueDetailTab = (props) => {
    const t = useTranslate();
    const [perPage,setPerPage]=useState(50)

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    const errortransform = (value,ertab) => {
        let retour = ''

        props.historiqueDetail.detail[value][ertab].forEach(er=>{
            let separation = er.split('/!')
            let noPoint=separation[0].replace('.','')
            retour+= (separation[1]) ? t(noPoint,{name: separation[1]})+', ' : t(noPoint)+', '
        })

        return retour
    }

    //Fonction pour itérer sur un objet 
    let historiqueDetailList = []
    if(props.historiqueDetail){
        let obj = props.historiqueDetail.detail;
        //On récupère les numéros de lignes avec un premier map
        historiqueDetailList = Object.keys(obj).map((value, ind) => ({
            ligne: value,
            //Ici on récupère les informations liés à chaque lignes
            erreurs: Object.keys(props.historiqueDetail.detail[value]).map((ertab)=>errortransform(value,ertab)),
            index: ind + 1,
        }))
    } 

    let defaultSorted = [
        {
          dataField: "name",
          order: "asc"
        }
    ];
    
    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "ligne",
            text: 'Ligne',
            editable: false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "erreurs",
            text: 'Erreurs',
            editable: false,
            sort: true,
            headerSortingStyle,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(props.cookies.histo_details_import_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = props.cookies.histo_details_import_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                props.setCookie("histo_details_import_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        props.setColumnOrganize(columnCookie)
    }, [])

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: historiqueDetailList.length,
    };

    return(
        <div className='col'>
            {(props.historiqueDetail) && 
                <>
                    <div>
                        <HeadInfos titre={t(props.historiqueDetail.name) } otherHeadInfos={[props.historiqueDetail.filename]} date={props.historiqueDetail.created_at}/>
                    </div>
                    {(props.loading) ? 
                        <div className='d-flex justify-content-center'>
                            <span><span>&nbsp;</span><div className="spinner-border spinner-border-lg"></div></span>
                        </div>
                    :
                        <small>
                            <ToolkitProvider
                                striped
                                keyField="index"
                                data={historiqueDetailList}
                                columns={props.columnOrganize}
                                defaultSorted={defaultSorted}
                                >
                                {(propts) => (
                                    <>
                                        <CustomDataTable 
                                            propts={propts}
                                            paginationOptions={paginationOptions}
                                            columns={columns}
                                            defaultSortDirection="asc"
                                        />
                                    </>
                                )}
                            </ToolkitProvider>
                        </small>
                    }       
                </>
            }
        </div>
    )
}

const HistoriqueImportDetail = () => {
    const [loading,error,historiqueDetail] = useLoadHistoriqueDetail()
    const t = useTranslate();
    const [cookies, setCookie] = useCookies(['histo_details_import_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    if(error){
        return (<SweetAlert show="true" title={t('unknowError')} type="error" />);
    }

    return (
        <div className="col">
            <CardPerso header={
                <div>
                    {t('detailHistory')}&nbsp;
                    <ColumDataTableReorganize 
                        columnOrganize={columnOrganize}
                        setColumnOrganize={setColumnOrganize}
                        setCookie={setCookie}
                        cookieName='histo_details_import_reorganize'
                        showColumnOrganize={showColumnOrganize}
                        setShowColumnOrganize={setShowColumnOrganize}
                        btnSwitch={false}//pour affiche ou non le btn swith
                        initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                    />
                </div>
            }>
               <HistoriqueDetailTab loading={loading} error={error} historiqueDetail={historiqueDetail} cookies={cookies} columnOrganize={columnOrganize} setColumnOrganize={setColumnOrganize} setCookie={setCookie}/> 
            </CardPerso>
        </div>
    )
}

export default HistoriqueImportDetail;