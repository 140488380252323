import React, {useState,useRef,useEffect} from "react";
import { Loading,CardPerso,DeleteActionButton,AttributeToStaff,ManualCountingButton,CancelInventoryDivisionBtn,ControlInventoryCountAdmin,DetailsInventoryDivisionAdminBtn,ExportArticleButton,AddMarkExportForm} from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";
import axios from 'axios'
import { useParams } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import filterFactory, { textFilter,selectFilter  } from 'react-bootstrap-table2-filter';
import ButtonDeleteMasse from '../ButtonDeleteMasse'
import HeadInventory from '../HeadInventory'
import AttributeInventoryToStaff from "../AttributeInventoryToStaff";
import CancelInventoryForm from '../CancelInventoryForm'
import HeadOptionsInventory from '../HeadOptionsInventory'
import { Badge,Modal} from "react-bootstrap";
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const useLoad = (posid,relance) => {
    const t =useTranslate()
    const [loading,setLoading] = useState(true)
    const [section,setSection]=useState(false)

    const [loadingDetail,setLoadingDetail] = useState(true)
    const [divisionInfo,setDivisionInfo]=useState({})
    const [divisionIdInfo,setDivisionIdInfo]=useState(false)
    const [divisionRhInfo,setDivisionRhInfo]=useState(false)

    const [restriction,setRestriction]=useState({
        go:true,
        grades:[]
    })

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+posid
            ).then((response) => {
                setLoading(false)
                if(response.data.rh==null || response.data.rh.length==0){
                    swal.fire(t('noRhForSection'), "", "error");
                }
                else{
                    let restriction = response.data.restriction.filter(value=>value.action==='readInventoryDetail')
                    if(restriction.length>0){
                        if(restriction[0].grade.length > 0){
                            setRestriction({
                                go:false,
                                grades: restriction[0].grade.map(value=>value.id)
                            })
                        }
                    }
                    setSection(response.data)
                }
            }).catch((error) => {
                if(error=='end_session') return;
                setLoading(false)
                let messageError
                if(error.response==undefined){
                    messageError='unknowError'
                }else if(error.response.status==403){
                    messageError='forbiddenGeneral'
                }else {
                    messageError='unknowError'
                }
                swal.fire(t(messageError), "", "error");
            })
        })()
    }, [])

    useEffect(function () {
        (async function() {
            if(divisionIdInfo){
                setLoadingDetail(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/get_one/"+divisionIdInfo
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");

                    }else{  
                        let obj = {}
                        //on réorganise la réponse, au lieu d'avoir différents comptes avec les memes articles, on regroupe tous les comptages dans un seul article 
                        response.data.data.inventaire_count.map(value=>{
                            let binsId = (value.bins_id!=null) ? value.bins_id : ''
                            //si l'article existe deja dans nos data, on lui ajoute un comptage
                            if(obj[value.article_id+'dd'+binsId]){
                                obj[value.article_id+'dd'+binsId]['rh'+value.rh_id]=value.total
                                obj[value.article_id+'dd'+binsId]['count'+value.rh_id]=value.id

                            }else{
                                //sinon on crée l'article 
                                //on initialise infoCount avec rh+l'id du staff avec son comptage et count+l'id du staff avec l'id de son comptage
                                let infoCount = {['rh'+value.rh_id]:value.total,['count'+value.rh_id]:value.id}
                                Object.entries(value).map(([key,item])=>{
                                    if(key!='rh_id' && key!='total' && key!='rh_name' && key!='id'){
                                        infoCount[key]=item
                                    }
                                })
                                obj[value.article_id+'dd'+binsId]=infoCount
                            }
                        })
                        setDivisionInfo(obj)
                        setLoadingDetail(false)
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError
                    if(error.response==undefined){
                        messageError='unknowError'
                    }else if(error.response.status==403){
                        messageError='forbiddenGeneral'
                    }else {
                        messageError='unknowError'
                    }
                    swal.fire(t(messageError), "", "error");
                })
                setLoadingDetail(false)
            }
        })()
    }, [divisionIdInfo,relance])
    
    return [loading,section,loadingDetail,divisionInfo,setDivisionIdInfo,setDivisionRhInfo,divisionRhInfo,setDivisionInfo,restriction,setRestriction]
}

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            rowStyle={ props.rowStyle }
            filter={ filterFactory() }
            hover
            condensed
            responsive
        />
    );
};

const ListInventoryBins = (props) => {
    const t = useTranslate()
    const [selectedRow,setSelectedRow]=useState([])
    const [selected,setSelected]=useState(false)
    const [loading,section,loadingDetail,divisionInfo,setDivisionIdInfo,setDivisionRhInfo,divisionRhInfo,setDivisionInfo,restriction,setRestriction] =useLoad(props.allInventoryBins.section_id,props.relance)
    const [staffList,setStaffList]=useState([])
    const [binsEdit,setBinsEdit]=useState(false)
    const [binsEditMasse,setBinsEditMasse]=useState(false)
    const [binsNum,setBinsNum]=useState('')
    const { id,zoneid } = useParams();
    const searchInput = useRef('')
    const [afficheTabBins,setAfficheTabBins] = useState(props.allInventoryBins.inventaire_division.slice(0,50));//détermine le nombre de lignes sélectionnable 
    const [showCancelModal,setShowCancelModal]=useState(false)
    const [idDivision,setIdDivision]=useState(false)//pour l'annulation du comptage
    const [show,setShow]=useState(false) 
    const [finishedDivision,setFinishedDivision]=useState([])
    const [wait,setWait]=useState(false)
    const [perPage,setPerPage]=useState(50)
    const [modalTitle,setModalTitle]=useState('') 

    const [lang, setLang] = useCookies(['lang']);

    const [addMark,setAddMark]=useState(true) 

    useEffect(function () {
        let arr=[]
        //ici on remplie le tableau finichedDivision avec tous les divisions qui ont un état 'terminé' pour comparer si son length est égale au length des dats d'origine
        props.allInventoryBins.inventaire_division.length!=0 && props.allInventoryBins.inventaire_division.map(value=>{
            if(value.all_count && value.same_count){
                arr.push(value.id)
            }
        })
        setFinishedDivision(arr)
    }, [props.allInventoryBins.inventaire_division])
    
    useEffect(function () {
        if(props.allInventoryBins.wait==2){
            let relanceAppel = setInterval(()=> {
                const response = axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire/get_one/"+id
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");

                    }else{  
                        if(response.data.data.status==2){
                            clearInterval(relanceAppel)
                            props.setAllInventoryBins(response.data.data)
                        }
                    }
                }).catch((error) => {
                    if(error=='end_session') return;
                    let messageError
                    if(error.response==undefined){
                        messageError='unknowError'
                    }else if(error.response.status==403){
                        messageError='forbiddenGeneral'
                    }else {
                        messageError='unknowError'
                    }
                    swal.fire(t(messageError), "", "error");
                })
            }, 15000);
        }
    }, [])

    const deleteBinsInventory = async (binsinventaireId) =>{
        const response = await axios.delete(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/delete_single/"+binsinventaireId
        ).then((response) => {
            let newData = props.allInventoryBins.inventaire_division.filter(value=>value.id!=binsinventaireId)
            props.setAllInventoryBins({...props.allInventoryBins,inventaire_division:newData})

            // mise à jour du nombre de ligne par page 
            let newAfficheTab = afficheTabBins.filter(value=>value.id!=binsinventaireId)
            setAfficheTabBins(newAfficheTab)

        }).catch((error) => {
            if(error=='end_session') return;
            let messageError
            if(error.response==undefined){
                messageError='unknowError'
            }else if(error.response.status==403){
                messageError='forbiddenGeneral'
            }else {
                messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
        })
    }

    const selectRow = (name,rowid) =>{
        setBinsEdit(false)//si une row à été sélectionnée par btn attribute dans les actions, on le passe à false pour éviter les erreurs au submit
        if(name=='all'){
            if(afficheTabBins.length == selectedRow.length){
                //Si tout à été selectionné, on décoche tout
                setSelected(false)
                setSelectedRow([])
                setBinsEditMasse(false)
            }else{
                setSelected(true)
                setBinsEditMasse(true)
                //Sinon on coche le reste
                let newArr = []
                //on ne coche que les lignes arrichées sur la page en cours
                afficheTabBins.map(value=>
                    newArr.push(value.id)
                )
                
                setSelectedRow(newArr)
            }
        }else{
            if(selectedRow.includes(rowid)){
                //si la case est cochée, on la décoche
                let newArr = selectedRow.filter(value=>value!=rowid)
                setSelectedRow(newArr)
                if(newArr.length!=0){
                    setBinsEditMasse(true)
                }else{
                    setBinsEditMasse(false)
                }
                if(afficheTabBins.length == selectedRow.length){
                    //Si tout est coché et qu'on en décoche un, le select all se décoche
                    setSelected(false)
                }
            }else{
                if(afficheTabBins.length == selectedRow.length+1){
                    //Si après avoir coché, toute la liste est cochée, on coche le select all
                    setSelected(true)
                }
                setBinsEditMasse(true)
                setSelectedRow([...selectedRow,rowid])
            }
        }
    }

    const attributeStaff = (zoneId,binsName,rhList) =>{
        setBinsEdit(zoneId)
        setBinsNum(binsName)
        if(searchInput.current){
            searchInput.current.focus()
        }

        //Si des rh ont déjà été attribué et qu'on veut en rajouter
        //on affiche les rh existants en dessous de l'input afin de les ajoute en plus au lieu de devoir les écraser 
        if(rhList.length!=0){
            let arr = []
            rhList.map(value=>
                arr.push({id:value.id,name:value.name+' '+value.firstname})
            )
            setStaffList(arr)
        }
    }

    const removeStaff = (id) =>{
        let newDatas = staffList.filter(value=>value.id!=id)
        setStaffList(newDatas)
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        props.setValidButtonAttribute(true)

        let obj = {}
        if(binsEdit){
            //en cas d'ajout de staff apres avoir créé la zone d'inventaire
            obj['inventaire_division']=[binsEdit]
        }else if(binsEditMasse){
            //en cas d'ajout de staff en masse par sélection 
            obj['inventaire_division']=selectedRow
        }
        if(staffList.length!=0){
            let arr=[]
            staffList.map(value=>
                arr.push(value.id)
            )

            obj['rh']=arr
        }

        if(selectedRow.length==0 && !binsEdit){
            swal.fire(t('errorNoBinsSelected'), "", "error");
        }else if(staffList.length==0){
            swal.fire(t('errorNoStaffSelected'), "", "error");
        }else{
            const response = await axios.put(process.env.REACT_APP_API_URL+"api/erp/inventaire_division/rh_attribution/"+id, obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");

                }else{ 
                    props.setRelance(Date.now())
                }
            }).catch((error) => {
                if(error=='end_session') return;
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        }

        setStaffList([])
        if(binsEdit) setBinsEdit(false)
        if(binsEditMasse) setBinsEditMasse(false)
        setSelected(false)
        setSelectedRow([])
        setBinsNum('')
    }

    const assignEmptyBinsToZero = async () =>{
        props.setValidButtonBinsZero(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/inventaire/all_zero_count_bins/"+id
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");

            }else{ 
                props.setRelance(Date.now())
            }
        }).catch((error) => {
            if(error=='end_session') return;
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
    }

    const selected_bins_page = (page,sizePerPage) =>{
        let indice = ((page-1)*sizePerPage);
        setAfficheTabBins(props.allInventoryBins.inventaire_division.slice(indice,indice+sizePerPage));
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing { from } to { to } of { size } Results
        </span>
    );

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );
    
    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        onPageChange: (page, sizePerPage) => {
            //Quand on change de page, on décoche tout
            setSelectedRow([])
            setSelected(false)
            selected_bins_page(page,sizePerPage)
        },
        paginationTotalRenderer: customTotal
    };


    let dataTable = new Array
    props.allInventoryBins.inventaire_division.length!=0 && props.allInventoryBins.inventaire_division.map((value,ind)=>{
        let binsNum = (value.bins_inventaire.fifth!=null) ? value.bins_inventaire.first+'-'+value.bins_inventaire.second+'-'+value.bins_inventaire.third+'-'+value.bins_inventaire.fourth+'-'+value.bins_inventaire.fifth : (value.bins_inventaire.fourth!=null) ? value.bins_inventaire.first+'-'+value.bins_inventaire.second+'-'+value.bins_inventaire.third+'-'+value.bins_inventaire.fourth : (value.bins_inventaire.third!=null) ? value.bins_inventaire.first+'-'+value.bins_inventaire.second+'-'+value.bins_inventaire.third : (value.bins_inventaire.second!=null) ? value.bins_inventaire.first+'-'+value.bins_inventaire.second : value.bins_inventaire.first

        let staffAttibue 
        (value.rh.length!=0) ? 
            value.rh.map((value,ind)=>{ 
                if(ind==0){
                    staffAttibue=value.name+' '+value.firstname
                }else{
                    staffAttibue+=' / '+value.name+' '+value.firstname
                }
                
            }) 
        : 
            staffAttibue=''

        let obj = {
            name: binsNum,
            masque:'',
            id: value.id,
            selectBtn: <input disabled={props.allInventoryBins.status!=0 || props.allInventoryBins.wait==1} checked={selectedRow.includes(value.id)} type="checkbox" onChange={()=>selectRow('one',value.id)}/>,
            action: 
                <span className="d-flex justify-content-between">
                    <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeDeleteX'} nameType={'delete'} toDelete={binsNum} customClickEvent={deleteBinsInventory}/>
                    <AttributeToStaff callback={attributeStaff} zoneId={value.id} zoneName={binsNum} rhList={value.rh}/>
                    <ManualCountingButton path={props.countingPath+'/'+value.id+'/'+value.bins_id+'/'+binsNum} divisionId={value.id} section={section} id={id}/>
                    <DetailsInventoryDivisionAdminBtn divisionId={value.id} setDivisionIdInfo={setDivisionIdInfo} setShow={setShow} rhIds={value.rh} setDivisionRhInfo={setDivisionRhInfo} restriction={restriction} setRestriction={setRestriction} section={section} posid={props.allInventoryBins.section_id} module={props.module} titleName={binsNum} setModalTitle={setModalTitle}/>
                    <CancelInventoryDivisionBtn divisionId={value.id} rhIds={value.rh} setShowCancelModal={setShowCancelModal} setDivisionRhInfo={setDivisionRhInfo} setIdDivision={setIdDivision}/>
                </span>,
            staff:staffAttibue,
            state:(value.rh.length==0) ? 'nonAttribue' : (value.all_count && value.same_count) ? 'finished' : (value.all_count && !value.same_count) ? 'wrongCount' : 'countNotFinished',
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    const filterByBinsNum = (filterVal, data) => {
        if (filterVal) {
            let arr = []
            data.filter(bins =>{ 
                if(bins.name.startsWith(filterVal)){
                    arr.push(bins)
                }
            });
            return arr
        }
        return data;
    }

    const selectOptions ={ 
        'finished':t('finished'),
        'wrongCount':t('wrongCount'),
        'countNotFinished':t('countNotFinished'),
        'nonAttribue':t('nonAttribue')
    };

    let columns = [];

    if(props.allInventoryBins.status==0 && props.allInventoryBins.wait==0){
        columns.push(
            {
                dataField: "action",
                text: 'Actions',
                editable: false,
                headerClasses: 'head-action-row',
                classes: 'body-action-row',
            },
        )
    }

    columns.push(
        {
            dataField: "selectBtn",
            text: '',
            editable: false,
            headerStyle: (column, colIndex) => {
                return {
                    width:'50px'
                };
            },
            headerFormatter: (column, colIndex) => ( 
                <input disabled={props.allInventoryBins.status!=0 || props.allInventoryBins.wait==1} checked={selected} type="checkbox" onChange={()=>selectRow('all')}/>
            ),
        },
        {
            dataField: "name",
            filter: textFilter({
                onFilter: filterByBinsNum
            }),
            text: 'Bins',
            editable: false,
        },
        {
            dataField: "staff",
            text: t('rh'),
            editable: false,
            filter: textFilter(),
        },
        {
            dataField: "state",
            text: t('state'),
            editable: false,
            formatter: cell => selectOptions[cell],
            filter: selectFilter({
                options: selectOptions,
            }),
        },
    )
    
    const rowStyle = (row, rowIndex) => {
        const style = {};

        if (selectedRow.includes(row.id)) {
            style.backgroundColor = '#00BFFF';

        }else if(row.state=='nonAttribue'){
            style.backgroundColor = '#ffffff';

        }else if(row.state=='wrongCount') {
            style.backgroundColor = '#fccfcf';//mauvais comptage

        }else if(row.state=='finished'){
            style.backgroundColor = '#d9fcd2';//finalisé

        }else{
            style.backgroundColor = '#ffffbb';//comptage non fini
        }
        return style;
    };

    const closeModal = () =>{
        setShow(false)
        props.setRelance(Date.now())
    }
    
    if(loading){
        return(<Loading></Loading>)
    }

    return(
        <div className="row">
            <Modal size="xl" show={show} onHide={() => closeModal()}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('inventory')+' : '+modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(loadingDetail) ? 
                        <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                    :
                        <ControlInventoryCountAdmin divisionInfo={divisionInfo} divisionRhInfo={divisionRhInfo} setDivisionInfo={setDivisionInfo}/>
                    }
                </Modal.Body>
            </Modal>
            <Modal size="md" show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('cancelInventory')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CancelInventoryForm divisionRhInfo={divisionRhInfo} setRelance={props.setRelance} idDivision={idDivision} setShowCancelModal={setShowCancelModal} />
                </Modal.Body>
            </Modal>
            <HeadInventory 
                inventoryInfos={props.allInventoryBins}
            />
            <div className="col">
                <CardPerso header={
                    <HeadOptionsInventory 
                        title='inventory' 
                        btnValid={false} 
                        btnReturn={false} 
                        btnRefresh={true}
                        btnReturnHome={true}
                        setRefreshPage={props.setRelance}
                        module={props.module}
                        posid={props.allInventoryBins.section_id}
                        inventoryData={props.allInventoryBins}
                        finishedDivision={finishedDivision}
                        id={id}
                        setWait={setWait}
                    />}>
                    <div>
                        {(props.allInventoryBins.wait==1 || props.allInventoryBins.wait==2 || wait) ?
                            <div className="alert alert-info d-flex align-items-center mt-2" role="alert">  
                                {(props.allInventoryBins.wait==1) ? t('inventoryCompareWait') : (props.allInventoryBins.wait==2) ? t('inventoryValidateWait') : t('inventoryCompareWait')}&nbsp;
                                <div className="spinner-border spinner-border-sm"></div>
                            </div>
                        :
                            <small>
                                <ToolkitProvider
                                    striped
                                    keyField="index"
                                    data={dataTable}
                                    columns={columns}
                                >
                                {(propst) => (
                                    <>
                                        {(selectedRow.length!=0) ?
                                            <ButtonDeleteMasse 
                                                selectedRow={selectedRow} 
                                                setRelance={props.setRelance}
                                                setSelectedRow={setSelectedRow}
                                                setSelected={setSelected}
                                                afficheTab={afficheTabBins}
                                                setAfficheTab={setAfficheTabBins}
                                                inputBar={searchInput}
                                            />
                                        :
                                            false
                                        }
                                        <div className="row d-flex align-items-center mb-2">
                                            <div className="col-6">
                                                <form onSubmit={(e)=>handleSubmit(e)}>
                                                    {(props.allInventoryBins.status==0 && props.allInventoryBins.wait==0) ?
                                                        <div className="row d-flex align-items-center">
                                                            <div className="col-9">
                                                                <AttributeInventoryToStaff
                                                                    searchInput={searchInput}
                                                                    section={section}
                                                                    staffList={staffList}                 
                                                                    setStaffList={setStaffList}
                                                                />
                                                            </div>
                                                            <div className="col">
                                                                <button disabled={props.validButtonAttribute || props.validButtonBinsZero} className="btn btn-primary" >
                                                                    {props.validButtonAttribute ? <div className="spinner-border spinner-border-sm"></div> : t('attribuer')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    :
                                                    (props.allInventoryBins.status==2) ?
                                                        <>&nbsp;&nbsp;
                                                            <div className="d-flex">
                                                                <ExportArticleButton filtre={{}} lang={lang.lang} addMark={addMark} title={t('downloadStockGap')} type="button" url={"api/erp/inventaire/csvitem/"+id}></ExportArticleButton>&nbsp;&nbsp;&nbsp;
                                                                <AddMarkExportForm setAddMark={setAddMark} addMark={addMark} />
                                                            </div>
                                                            <span className="alert alert-success d-flex align-items-center mt-2 text-dark fs-4" role="alert">{t('finalizedInventory')}&nbsp;<FontAwesomeIcon icon={faCheckCircle} /></span>
                                                        </>
                                                    :
                                                        false
                                                    }
                                                    <div className="fw-bold">{binsNum}</div>
                                                </form>
                                            </div>
                                            {(props.allInventoryBins.status==0) ?
                                                <div className="col-6 text-end">
                                                    <button 
                                                        disabled={props.validButtonAttribute || props.validButtonBinsZero} 
                                                        className="btn btn-light"
                                                        onClick={() => {
                                                            swal
                                                            .fire({
                                                                title: t('confirmAssignCountZeroEmptyBins')+' ?',
                                                                icon: "warning",
                                                                type: "question",
                                                                showCancelButton: true,
                                                                confirmButtonColor: "#3085d6",
                                                                cancelButtonColor: "#d33",
                                                                confirmButtonText: t('btnYes'),
                                                                cancelButtonText: t('btnNo'),
                                                            })
                                                            .then((result) => {
                                                                if (result.value) {
                                                                    assignEmptyBinsToZero()
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {props.validButtonBinsZero ? <div className="spinner-border spinner-border-sm"></div> : t('assignCountZeroEmptyBins')}
                                                    </button>
                                                </div>
                                            : 
                                                false
                                            }
                                        </div>
                                        <div className="mb-2">
                                            {(staffList.length!=0) ? 
                                                staffList.map(value=>(
                                                    <span key={value.id} >
                                                        <Badge bg="primary" text="white">{value.name}&nbsp;&nbsp;<span className="text-danger pointer" onClick={()=>removeStaff(value.id)}>{<FontAwesomeIcon icon={faTimesCircle} />}</span></Badge>&nbsp;&nbsp;
                                                    </span>
                                                ))
                                            :
                                                false
                                            }
                                        </div>
                                        <div className="position-relative">
                                            <CustomDataTable
                                                propst={propst}
                                                paginationOptions={paginationOptions}
                                                rowStyle={rowStyle}
                                                columns={columns}
                                                defaultSortDirection="asc"
                                            />
                                        </div>
                                    </>
                                )}
                                </ToolkitProvider>
                            </small>
                        }
                    </div>
                </CardPerso>
            </div>
        </div>
    )
}

export default ListInventoryBins;
