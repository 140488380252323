import React, {useState,useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso, DeleteActionButton} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import axios from 'axios'
import swal from "sweetalert2";
import {scrollXDataTable} from 'app/scrollXDataTable';
import Moment from 'react-moment';
import { Link } from "react-router-dom";

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            remote
            pagination={ paginationFactory(props.paginationOptions) }
            noDataIndication={t('tableNoData')}
            hover
            condensed
            responsive
        />
    );
};

const ModalErrorsInvoicingRecords = (props) => {
    const t = useTranslate();
    const [perPage, setPerPage] = useState(50);

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let datas = new Array
    props.errorsInvoicingRecords && Object.entries(props.errorsInvoicingRecords).map(([key,value], ind)=> {
        let obj = {
            id: value.id,
            ticket: <Link to={"/erp/caisse/ticket/"+value.id} >{value.bar}</Link>,
            date: <Moment format="DD/MM/YYYY">{value.date_validation}</Moment>,
            index: ind + 1,
        }
        
        datas.push(obj)
    })

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: datas.length
    };

    let columns =[
        {
            dataField: "ticket",
            text: t('ticket')+' n°',
            editable: false,
        },
        {
            dataField: "date",
            text: 'Date',
            editable: false,
        },
    ];

    return(
        <>
            <small>
                <ToolkitProvider
                    keyField="index"
                    data={datas}
                    columns={ columns }
                >
                    {(propst) => (
                        <div className="position-relative">
                            <CustomDataTable
                                propst={propst}
                                paginationOptions={paginationOptions}
                                columns={columns}
                            />
                        </div>
                    )}
                </ToolkitProvider>
            </small>
        </>
    )
}

export default ModalErrorsInvoicingRecords;