import React, {useState,useEffect,useContext} from "react";
import { CardPerso,Loading,ControlAccess, ColumDataTableReorganize } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {scrollXDataTable} from 'app/scrollXDataTable';
import { useCookies} from 'react-cookie';
import { IsAuthContext } from 'app/App';

const CustomDataTable = (props) => {
    const t = useTranslate();
    
    useEffect(() => {
        scrollXDataTable(window.screen.width,props.columns.length)
    }, [window.screen.width,props.columns.length]);
    
    return (
        <BootstrapTable
            {...props.propst.baseProps}
            bootstrap4
            pagination={paginationFactory(props.paginationOptions)}
            noDataIndication={t('tableNoData')}
            defaultSorted={props.defaultSorted}
            cellEdit={cellEditFactory({ 
                mode: "dbclick",
                blurToSave: true
            })}
            defaultSortDirection={props.defaultSortDirection}
            hover
            condensed
            responsive
        />
    );
};

const RhGoalsAttribute = (props) => {
    const t = useTranslate()
    let { SearchBar } = Search;
    const [perPage,setPerPage]=useState(50)
    const [rhList,setRhList] = useState(props.section.rh.filter(({ archive })=>!archive).map(({id,name,firstname,grade},ind) => ({
        id,
        name: name+' '+firstname,
        grade: grade.name,
        cattc: (props.commerceGoals.rhgoals.filter(value=>value.rh_id==id).length > 0) ? props.commerceGoals.rhgoals.filter(value=>value.rh_id==id)[0].cattc : 0,
        pmttc: (props.commerceGoals.rhgoals.filter(value=>value.rh_id==id).length > 0) ? props.commerceGoals.rhgoals.filter(value=>value.rh_id==id)[0].pmttc : 0,
        iv: (props.commerceGoals.rhgoals.filter(value=>value.rh_id==id).length > 0) ? props.commerceGoals.rhgoals.filter(value=>value.rh_id==id)[0].iv : 0,
        index: ind + 1,
    })))

    const [cookies, setCookie] = useCookies(['goal_attribute_reorganize']);
    const [columnOrganize,setColumnOrganize]=useState([])
    const [showColumnOrganize,setShowColumnOrganize]=useState(false) 

    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let sumGoals={
        cattc:0
    }
    props.commerceGoals.rhgoals.map(value=>{
        sumGoals.cattc+=value.cattc
    });
    const [titleGoals,setTitleGoals]=useState({
        cattc:props.commerceGoals.cattc-sumGoals.cattc
    });

    const load = async (id,field,newValue,done) => {
        let key=field;
        var obj = {rh:id};
        obj[key]=newValue.replace(',','.');
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/pos/rhgoals/"+props.commerceGoals.id, obj
        ).then((response)=> {
            if(response.data.valid){
                let sumGoals={
                    cattc:0
                }
                response.data.data.rhgoals.map(value=>{
                    sumGoals.cattc+=value.cattc
                });
                setTitleGoals({
                    cattc:props.commerceGoals.cattc-sumGoals.cattc
                })
                return done()
            }
            else {
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                 }
                 interms=interms.split(',')
                 let messageError='';
                 interms.forEach(interm=>messageError+=t(interm)+'. ')
                 messageError=messageError.replace('undefined','');
                return done({
                    valid: false,
                    message: messageError
                });
            }
        }).catch((error)=>{
            let messageError = (error.response.status==403) ? t('forbiddenGeneral') : t('unknowError');
            return done({
                valid: false,
                message: messageError
            });
        })
    }

    let defaultSorted = [
        {
          dataField: "name",
          order: "asc"
        }
    ];

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    let columns = [
        {
            dataField: "name",
            text: t('tableName'),
            editable:false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "grade",
            text: "Grades",
            editable:false,
            sort: true,
            headerSortingStyle,
        },
        {
            dataField: "cattc",
            text: "CA TTC* - Reste à attribuer: "+new Intl.NumberFormat('fr-FR').format(titleGoals.cattc)+' '+props.devise,
            validator: (newValue, row, column, done) => {
                load(row.id,column.dataField,newValue,done)
                return {
                    async: true
                };
            },
            sort: true,
            headerSortingStyle,
            headerClasses: (titleGoals.cattc==0) ? 'text-success' : 'text-danger',
            formatter: (rowData) => new Intl.NumberFormat('fr-FR').format(rowData)+' '+props.devise,
        },
        {
            dataField: "pmttc",
            text: "PM TTC*",
            validator: (newValue, row, column, done) => {
                load(row.id,column.dataField,newValue,done)
                return {
                    async: true
                };
            },
            sort: true,
            headerSortingStyle,
            formatter: (rowData) => new Intl.NumberFormat('fr-FR').format(rowData)+' '+props.devise
        },
        {
            dataField: "iv",
            text: "IV*",
            validator: (newValue, row, column, done) => {
                load(row.id,column.dataField,newValue,done)
                return {
                    async: true
                };
            },
            sort: true,
            headerSortingStyle,
        },
    ];

    useEffect(function () {
        let columnCookie
        let arrCol = []
            
        if(cookies.goal_attribute_reorganize){
            let newValueToIncludeInCookie
            let cookieLecture = cookies.goal_attribute_reorganize.split(',')

            let already_done=[];
            cookieLecture.map(value=>{
                columns.map(item=>{
                    if(item.dataField==value){
                        arrCol.push(item)
                        already_done.push(value);
                    }
                })
            })
            columns.map(item=>{
                if(!already_done.includes(item.dataField)){ 
                    //si une nouvelle à été ajouté aux customsField, elle n'est pas ajoutée desuite au cookie de reorganisation si celui ci existe
                    //dans le cas où il n'existerait pas, on va créer un nouveau cookie afin de l'inclure pour ne pas avoir d'erreur sur les export 
                    newValueToIncludeInCookie=true
                    arrCol.push(item);
                }
            })

            columnCookie = arrCol
            if(newValueToIncludeInCookie==true){ 
                let arr = []
                arrCol.map(value=>
                    arr.push(value.dataField)
                )
                setCookie("goal_attribute_reorganize",arr.join(),{ path: "/", expires: expire_date })
            }
        }else{
            columnCookie = columns
        } 
        setColumnOrganize(columnCookie)
    }, [titleGoals.cattc])

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={ text }
            role="presentation"
            className="dropdown-item"
            onMouseDown={ (e) => {
                e.preventDefault();
                onSizePerPageChange(page);
                setPerPage(page)
            } }
        >
            <a
                href="#"
                tabIndex="-1"
                role="menuitem"
                data-page={ page }
                style={ { color: 'black' } }
            >
                { text }
            </a>
        </li>
    );

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        sizePerPageOptionRenderer,
        sizePerPage: perPage,
        showTotal: true,
        totalSize: rhList.length,
    };

    return (<>
        {columnOrganize.length!=0 && 
            <small>
                <ToolkitProvider
                striped
                keyField="index"
                data={rhList}
                columns={columnOrganize}
                search
                >
                {(propst) => (
                    <>
                        <div className="d-flex justify-content-between">
                            <div>
                                <ColumDataTableReorganize 
                                    columnOrganize={columnOrganize}
                                    setColumnOrganize={setColumnOrganize}
                                    setCookie={setCookie}
                                    cookieName='goal_attribute_reorganize'
                                    showColumnOrganize={showColumnOrganize}
                                    setShowColumnOrganize={setShowColumnOrganize}
                                    btnSwitch={false}//pour affiche ou non le btn swith
                                    initSwith={true}//initialier à false il affichera par défaut le toggle des colonnes, sur true il affichera le drag and drop pour changer la position des colonnes
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <span className="mb-2 me-1">{t('tableSearch')}:</span>
                                <SearchBar {...propst.searchProps} className="mb-2" />
                            </div>
                        </div>
                        <CustomDataTable 
                            propst={propst}
                            paginationOptions={paginationOptions}
                            defaultSorted={defaultSorted}
                            columns={columns}
                            defaultSortDirection="asc"
                        />
                    </>
                )}
                </ToolkitProvider>
            </small>
        }
    </>
    )
}

const GoalsAttribute = (props) => {
    const t =useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [commerceGoals,setCommerceGoals] = useState(false)
    const {authParam} = useContext(IsAuthContext)
    let devise = authParam.company.devise;

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setCommerceGoals(false)
        const response  = await axios.get(process.env.REACT_APP_API_URL+"api/pos/objectif/period/"+props.section.id+'/'+data.begin+'/0'
            ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                if(!response.data.period){
                    setApiError(t('noObjectif'))
                }
                else{
                    setCommerceGoals(response.data.period)
                }
            }
        }).catch((error) => {
            if(error=='end_session') return;
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

    if(props.from=='pos' && !props.restriction.go){
        return(
            <ControlAccess section={props.section} restriction={props.restriction} setRestriction={props.setRestriction} moduleId={props.section.id} noMarge={true}/>
        )
    }

    return(
        <div className="row" style={{marginTop: (props.noUp) ? '0' : '-60px'}}>
            <div className="col-12">
                <CardPerso header={t('attribuer')+' '+t('objectifs')}>
                    <div className="row">
                        <div className="col-6">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group row">
                                    <label htmlFor="begin">{t('day')}</label>
                                    <input className="form-control" type="date"{...register('begin',{required: true})}/>
                                    {errors.begin?.type === 'required' && (
                                    <div className="text-danger col-12 mt-1 ml-2">
                                        {t('beginRequired')}
                                    </div>
                                    )}
                                </div>
                                {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                                <button disabled={validButton} className="btn btn-primary mt-3">
                                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                                </button>
                            </form>
                        </div>
                        <div className="col-5 offset-1">
                            {(commerceGoals) &&
                                <div className="row text-center" style={{borderLeft:'solid 1px #e5e5e5'}}>
                                    <div className="col-12 text-center">
                                        <p><strong>{t('objectifs')+' '+t('shop')}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <span style={{color: '#5679df'}}><strong>CA TTC</strong>: {new Intl.NumberFormat('fr-FR').format(commerceGoals.cattc)+' '+devise}</span>
                                    </div>
                                    <div className="col-12">
                                        <span style={{color: '#dfa656'}}><strong>PM TTC</strong>: {new Intl.NumberFormat('fr-FR').format(commerceGoals.pmttc)+' '+devise}</span>
                                    </div>
                                    <div className="col-12">
                                        <span style={{color: '#a945b2'}}><strong>IV</strong>: {commerceGoals.iv+' '+t('items')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(commerceGoals) && <>
                        <hr className="col-8 offset-2 mt-3" />
                        <RhGoalsAttribute section={props.section} commerceGoals={commerceGoals} devise={devise} />
                    </>}
                </CardPerso>
            </div>
        </div>
    )
}

export default GoalsAttribute;
