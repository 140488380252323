import React, {useState,useEffect,useContext} from "react";
import { Modal } from "react-bootstrap";
import { useTranslate } from 'react-redux-multilingual'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import {constructionDocument} from '@gull'
import { IsAuthContext } from 'app/App';

const DocumentColumDataTableReorganize = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext);
    const [switchAction,setSwitchAction]=useState(props.initSwith) 
    const [validButton,setValidButton]=useState(true)//ne sert à rien à part satisafire le constructionDocument
    const [choice,setChoice]=useState([]) 
    const [reload,setReload]=useState(false)

    useEffect(function () {
        let arr=[]
        //props.columnOrganize contient que les colonnes ou lignes affichées
        props.columnOrganize.map(value=>{
            let temp = props.possibilities[value]
            temp.toggle=true//On passe true car ces données là sont à afficher
            temp.index=value
            arr.push(temp)
        })
        //Pour toutes les autres données qui ne font pas parti de props.columnOrganize, on passe un toggle false pour ne pas les afficher
        Object.entries(props.possibilities)
        .map(column => {
            if(!arr.some(objet => objet.index == column[0])){
                let temp = props.possibilities[column[0]]
                temp.toggle=false
                temp.index=column[0]
                arr.push(temp)
            }
        })
        setChoice(arr)
    }, [props.columnOrganize,reload])

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const newColumn = [...props.columnOrganize];
        const [reorderedItem] = newColumn.splice(result.source.index, 1);
        newColumn.splice(result.destination.index, 0, reorderedItem);

        props.setColumnOrganize(newColumn);
    }
    
    const handleSelect = (column,ind) => {//selection des colonnes qui vont s'afficher au bon endroit ou s'effacer
        if(column.toggle){
            //Si la donnée est deja affichée, on la retire de la vue 
            props.setColumnOrganize([...props.columnOrganize.filter(value => value !== column.index)]);
        }
        else{
            let arr = [];
            choice.map(value=>{
                if(props.columnOrganize.includes(value.index)) arr.push(value.index)
                else if(value.index==column.index) arr.push(value.index)
            })
            props.setColumnOrganize(arr)
        } 
    }

    const callBackConstruct = (response) => {
        return true
    };

    const closeModal = () =>{
        if(props.saveBack){
            let obj = {}
            obj[props.saveBack]=props.columnOrganize.join(',')
            constructionDocument(setValidButton,props.id,callBackConstruct,t,obj)
        }
        props.setShowColumnOrganize(false)
        if(props.btnSwitch) setSwitchAction(false)
    }

    const handleShow = () => {
        props.setShowColumnOrganize(true)
        setReload(Date.now())
    }
    
    return(<>
            <Modal show={props.showColumnOrganize} onHide={() => closeModal()}>
                <Modal.Header closeButton className="d-flex flex-wrap">
                    <Modal.Title>{t('reorganizeColum')}</Modal.Title><br />
                    {(props.btnSwitch) &&
                        <Form>
                            <Form.Check 
                                type="switch"
                                id="custom-switch"
                                label={t('modifColumnPosition')}
                                checked={switchAction}
                                onClick={(e) => e.stopPropagation()} 
                                onChange={()=>setSwitchAction(!switchAction)}
                            />
                        </Form>
                    }
                </Modal.Header>
                <Modal.Body>
                    {(!switchAction) ?
                        <div>
                            <div className="alert alert-info">{t('clickToHideOrShow')}</div>
                            <ul style={{paddingLeft:"0", listStyle: 'none'}}>
                                {choice.map((column,ind) => 
                                    <div className="row"  key={ column.index }>
                                        <li
                                            type="button"
                                            className={ `btn btn-sm ${column.toggle ? "btn-info" : "btn-outline-primary"} mb-1 p-2` }
                                            data-toggle="button"
                                            aria-pressed={ column.toggle ? 'true' : 'false' }
                                            onClick={ 
                                                () =>handleSelect(column,ind) 
                                            }
                                        >
                                            <big>
                                                {column.name=='tgc' ? t(column.name,{taxe:authParam.company.nomenclature_tgc}) : t(column.name)}
                                            </big>&nbsp;&nbsp;
                                            <span>{(column.toggle) ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}</span>
                                        </li>
                                    </div>
                                )}
                            </ul>
                        </div>
                    :
                        <div>
                            <div className="alert alert-info">{t('dragAndDropToChangePosition')}</div>
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="column">
                                    {(provided) => (
                                        <ul  {...provided.droppableProps} ref={provided.innerRef} style={{paddingLeft:"0", listStyle: 'none'}}>
                                            {props.columnOrganize.map((value, index) =>{
                                                return(
                                                    <Draggable key={"column"+value} draggableId={"column"+value} index={index}>
                                                        {(provided) => (
                                                            <div className="row">
                                                                <li 
                                                                    ref={provided.innerRef} 
                                                                    {...provided.draggableProps} 
                                                                    {...provided.dragHandleProps}
                                                                    className="btn btn-sm btn-primary mb-1 p-2"
                                                                >
                                                                    <big>
                                                                        {props.possibilities[value].name=='tgc' ? t(props.possibilities[value].name,{taxe:authParam.company.nomenclature_tgc}) : t(props.possibilities[value].name)}
                                                                    </big>                                            
                                                                </li>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            {props.notButton ?
                    <div onClick={()=>handleShow()}>{t(props.nameBtn)}</div>
                :
                    <div className={`btn btn-sm ${props.showColumnOrganize ? 'btn-primary' : 'btn-outline-primary'}`} onClick={()=>handleShow()}>{t(props.nameBtn)}</div>
            }
        </>
    )
}

export default DocumentColumDataTableReorganize;

