import { modePaiementMustBeTranslate,modePaiementFidelite } from './modePaiementDefaultAll';
import { espace_mille } from './espace_mille';
import {bbgShops} from './bbgShops'
import swal from "sweetalert2";
import { conditionsRetour } from 'app/conditionsRetour';

export const epson_tm_h6000V = async (printMode,posid,currentTicket,t,devise,peripheriqueParam,from,authParam,totalPointFideliteClient,totalPointFideliteTicket,totalCashback,cashbackTicket,fideliteMode) =>{
    let paramArticle = ['name']
    let paramInfoVente = ['created_at','bar','rh','client']
    let paramTaxes = ['ttc']

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==posid)
    let modulePos = authParam.modules.find(value=>value.name=='pos')
    let ticketDate = new Date(currentTicket.date_validation)
    let formatedParamArticle = []
    let formatedInfoShop = (thisShop && thisShop.infos_superieur_ticket!=null) ? thisShop.infos_superieur_ticket : []
    let fomatedPrice = []
    let formatedInfoVente = []
    let formatedTotaux = []
    let formatedTaxes = []
    let formatedReglement = []
    let formatedNoteArticle = []

    let messageBye = (thisShop) ? [thisShop.name,t('messageEndReceipt')] : ["",""]
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    //section calcule des totaux
    let totalAchat = 0
    let totaltgc = {}
    currentTicket.ticket_detail.map(item=>{
        totalAchat+=item.prix_remise
        if(item.tgc!=0){//on exécute pas cette action sur le taux 0
            if(totaltgc[item.tgc]){
                //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
            }else{
                //Si aucun taux existe on ajoute la taxe 
                totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
            }
            
        }

        //ancien code 
        // if(item.article_retour!=null){
        //     totaltgc={...totaltgc,[item.article_retour.tgc]:totaltgc[item.article_retour.tgc]+=item.prix_remise}
        // }
        // if(item.article!=null){
        //     totaltgc={...totaltgc,[item.article.tgc]:totaltgc[item.article.tgc]+=item.prix_remise}
        // }
    })

    let tgcForTicketPdf={}
    Object.entries(totaltgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    //end section calcule des totaux

    paramInfoVente.forEach(value=>{//Formatage des infos de vente
        if(currentTicket[value]){
            switch (value) {
                case 'rh':
                    formatedInfoVente.push(t('cashier')+': '+currentTicket[value].name+' '+currentTicket[value].firstname)
                break;
                case 'client':
                    let clientName = (currentTicket[value]!=null && currentTicket[value].name!=null && currentTicket[value].firstname!=null) ? currentTicket[value].name+' '+currentTicket[value].firstname : (currentTicket[value]!=null && currentTicket[value].name!=null) ? currentTicket[value].name : ''
                    let clientMoreInfo = (currentTicket[value].tel!=null) ? ' / '+currentTicket[value].tel : ''
                    formatedInfoVente.push(t('customer')+': '+clientName+clientMoreInfo)
                break;
                case 'created_at':
                    formatedInfoVente.push(ticketDate.toLocaleString())
                break;
                default :
                    formatedInfoVente.push(currentTicket[value])
            }
        }
    })

    currentTicket.ticket_detail.forEach((value)=>{//Formatage du nom de l'article 
        let arr = []
        let n = 0
        while(n<paramArticle.length){
            if(n<paramArticle.length){
                if(value.special=='gift_card'){
                    if(paramArticle[n] == 'name'){
                        arr.push(t('giftcard')+' '+value.details.reference)
                    }else{
                        arr.push(value.details[paramArticle[n]])
                    }
                    
                }else if(value.special=='retour'){
                    arr.push((value.article_retour.sku) ? value.article_retour.sku+' '+value.article_retour[paramArticle[n]] : value.article_retour[paramArticle[n]])
                }else{
                    arr.push((value.article.sku) ? value.article.sku+' '+value.article[paramArticle[n]] : value.article[paramArticle[n]])
                }
                n++
            }
            if(n==paramArticle.length){
                let newArr = arr.join("/@/")
                formatedParamArticle.push(newArr)
            }
        }
    })
    
    currentTicket.ticket_detail.forEach((value)=>{//Formatage des infos prix des articles
        let arr = []
        if(printMode!='giftprint'){
            if(value.special=='retour'){
                arr.push('')
                arr.push(t('itemReturn'))
                
            }else if(value.special=='gift_card'){
                arr.push('')
                arr.push(espace_mille(value.prix_remise,digit)+devise)

            }else{
                arr.push(value.tgc+'%')
                arr.push(espace_mille(value.unitaire,digit)+devise)
                
            }

            arr.push('x'+value.quantity)
            if(+value.remise>0){
                arr.push('-'+value.remise)
            }else{
                arr.push(value.remise)
            }
            arr.push(espace_mille(value.prix_remise,digit)+devise)
            if(value.promotion_intitule!=null) arr.push(value.promotion_intitule)
        }else{
            arr.push('')
            arr.push('')
            arr.push('x'+value.quantity)
            arr.push('')
            arr.push(espace_mille('',digit))
        }

        fomatedPrice.push(arr.join('/@/'))
    })

    currentTicket.ticket_detail.forEach((value)=>{//Formatage des notes
        if(value.note!=null || value.ecotaxe!=null){
            //Si l'article à une éco participation, on l'affiche dans la note de l'article
            //La fonction replace permet de retirer toutes les balises html existante sur le string qu'on récupère
            let note = (value.note != null && value.note != '' && value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? t('dont')+' '+t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe),digit)+devise+' \n '+value.note.replace(/<[^>]*>|\n/g, '') : 
            (value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? t('dont')+' '+t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe),digit)+devise : 
            (value.note != null && value.note != '') ? value.note.replace(/<[^>]*>|\n/g, '') : ''

            formatedNoteArticle.push(note)
        }else{
            formatedNoteArticle.push('')
        }
    })
    
    paramTaxes.forEach(value=>{//Formatage des totaux
        let arr = []
        switch (value) {
            case 'ttc':
                arr.push('Total '+value.toUpperCase())
                arr.push(espace_mille(totalAchat,digit)+devise)
            break;
            case 'ht':
                arr.push('Total '+value.toUpperCase())
                if(tgcTotal>0){
                    arr.push(espace_mille(+totalAchat - +tgcTotal,digit)+devise)
                }else{
                    arr.push(espace_mille(+totalAchat,digit)+devise)
                }
            break;
        }

        formatedTotaux.push(arr.join('/@/'))
    })

    Object.entries(tgcForTicketPdf).forEach(([taxe,value])=>{//Formatage des taxes
        let arr = []
        if(value > 0){
            arr.push('Total '+taxe+'%')
            arr.push(espace_mille(value,digit)+devise)
        }
        
        if(arr.length!=0) formatedTaxes.push(arr.join('/@/'))
    })

    formatedTaxes.push('Total '+authParam.company.nomenclature_tgc+'/@/'+espace_mille(tgcTotal,digit)+devise)

    if(formatedTaxes.length==0){
        formatedTaxes.push('/@/')
    }

    if(currentTicket.remboursement_records.length!=0 || currentTicket.paiement_records.length!=0){
        if(currentTicket.remboursement_records.length!=0){
            currentTicket.remboursement_records.forEach((value)=>{//Formatage des règlements
                let arrRefund = []
    
                if(value.mode=='espece'){
                    arrRefund.push(t('refundEspece'))
                }else{
                    arrRefund.push(t('refundOther'))
                }
    
                arrRefund.push(espace_mille(value.montant,digit)+devise)

                if(value.mode=='autre'){ 
                    arrRefund.push('refundNote')
                    arrRefund.push(value.note.replace(/<[^>]*>|\n/g, ''))
                }
    
                formatedReglement.push(arrRefund.join('/@/'))
            })
        }
        if(currentTicket.paiement_records.length!=0){
            currentTicket.paiement_records.forEach((value)=>{//Formatage des règlements
                let arr = []

                if(modePaiementMustBeTranslate.includes(value.mode)){
                    arr.push(t(value.mode))
                }else if(modePaiementFidelite.includes(value.mode)){
                    arr.push(t('loyalty'))
                }else{//replace est utilisé ici pour supprimer les "" potentiels sur le nom du mode de paiement
                    arr.push(value.mode.replace(/["]/g,'' ))
                }

                arr.push(espace_mille(value.montant,digit)+devise)
                if(value.rendu!=0 && value.mode=='espece'){
                    arr.push(t('deposited')+' : '+espace_mille(value.verse,digit)+devise)
                    arr.push(t('changeBack')+' : '+espace_mille(value.rendu,digit)+devise)
                }
                
                formatedReglement.push(arr.join('/@/'))
            })
        }
    }else{
        formatedReglement.push('/@/')
    }

    let totalFidelite
    let fidelieTicket

    if(fideliteMode=='cashback'){
        totalFidelite = espace_mille(+totalCashback,digit)+devise
        fidelieTicket = espace_mille(+cashbackTicket,digit)+devise
    }else{
        totalFidelite = espace_mille(+totalPointFideliteClient,digit)+'Pts'
        fidelieTicket = espace_mille(+totalPointFideliteTicket,digit)+'Pts'
    }

    let article = '"'+formatedParamArticle.join('/@!')+'"'
    let infoShop = '"'+formatedInfoShop.join('/@!')+'"'
    let price = '"'+fomatedPrice.join('/@!')+'"'   
    let infoVente = '"'+formatedInfoVente.join('/@!')+'"'
    let totaux = '"'+formatedTotaux.join('/@!')+'"'  
    let taxes = '"'+formatedTaxes.join('/@!')+'"'
    let reglement = '"'+formatedReglement.join('/@!')+'"' 
    let barCode = currentTicket.bar
    let msgBye = '"'+messageBye.join('/@!')+'"'
    let codeShop = (thisShop) ? '"'+thisShop.code+'"' : '" "'
    let idShop = (thisShop) ? '"'+thisShop.id+'"' : '" "'
    let host = '"'+peripheriqueParam.imprimante_ticket.host+'"'
    let conditionsRetourMsg = (thisShop && thisShop.infos_inferieur_ticket!=null) ? '"'+thisShop.infos_inferieur_ticket.replace(/[\n\r]/g,'</text-line></align><align mode="center"><text-line>')+'"'  : '" "'
    let articleNote = '"'+formatedNoteArticle.join('/@!')+'"'
    articleNote = articleNote.replace(/[\n\r]/g,'</text-line></align><align mode="left"><text-line>')
    let totalFideliteClient = (modulePos.params.fidelite.active && totalFidelite) ? '"'+'Total : /@/'+totalFidelite+'"' : ''
    let totalFideliteTicket = (modulePos.params.fidelite.active && fidelieTicket) ? '"'+t('onReceipt')+' : /@/'+fidelieTicket+'"' : ''

    if(peripheriqueParam && peripheriqueParam.imprimante_ticket && peripheriqueParam.imprimante_ticket.model=='epson_tm_h6000V'){
        const response  = await fetch('http://localhost/portcom/print.php',{
            method: 'POST',
            body: JSON.stringify({
                item:article.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                price:price, 
                infoShop:infoShop.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                infoVente:infoVente.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                totaux:totaux, 
                taxes:taxes, 
                reglement:reglement.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                barCode:barCode, 
                conditionsRetour:conditionsRetourMsg.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                msgBye:msgBye.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                codeShop:codeShop,
                printMode:printMode, 
                host:host,  
                articleNote:articleNote.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                idShop:idShop,
                totalPointFideliteClient:totalFideliteClient.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                totalPointFideliteTicket:totalFideliteTicket.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                tgc:authParam.company.nomenclature_tgc
            })
        }).then((response) => {
        }).catch((error) => {
        })
    }else{
        if(from=='historiqueVente'){ 
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }
    }
}

export const epson_tm_h6000VOrder = async (printMode,posid,currentTicket,t,devise,peripheriqueParam,from,authParam) =>{
    let paramArticle = ['name']
    let paramInfoVente = ['created_at','bar','rh','client','section_collect','prevu']
    let paramTaxes = ['ttc']

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==posid)
    let ticketDate = new Date(currentTicket.created_at)
    let deliveryDate = new Date(currentTicket.prevu)

    let formatedParamArticle = []
    let formatedInfoShop = (thisShop && thisShop.infos_superieur_ticket!=null) ? thisShop.infos_superieur_ticket : []
    let fomatedPrice = []
    let formatedInfoVente = []
    let formatedTotaux = []
    let formatedTaxes = []
    let formatedReglement = []
    let formatedNoteArticle = []

    let messageBye = (thisShop) ? [thisShop.name,t('messageEndReceipt')] : ["",""]
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    //section calcule des totaux
    let totalAchat = 0
    let totaltgc = {}
    currentTicket.ticket.ticket_detail.map(item=>{
        totalAchat+=item.prix_remise
        if(item.tgc!=0){//on exécute pas cette action sur le taux 0
            if(totaltgc[item.tgc]){
                //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
            }else{
                //Si aucun taux existe on ajoute la taxe 
                totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
            }
            
        }
        // if(item.article!=null){
        //     totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
        // }
    })

    let tgcForTicketPdf={}
    Object.entries(totaltgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    //end section calcule des totaux

    paramInfoVente.forEach(value=>{//Formatage des infos de vente
        if(currentTicket[value] || currentTicket.ticket[value]){
            switch (value) {
                case 'rh':
                    formatedInfoVente.push(t('cashier')+': '+currentTicket.ticket[value].name+' '+currentTicket.ticket[value].firstname)
                break;
                case 'client':
                    let clientName = (currentTicket.ticket[value].name!=null && currentTicket.ticket[value].firstname!=null) ? currentTicket.ticket[value].name+' '+currentTicket.ticket[value].firstname : (currentTicket.ticket[value].name!=null) ? currentTicket.ticket[value].name : ''
                    let clientMoreInfo = (currentTicket.ticket[value].tel!=null) ? ' / '+currentTicket.ticket[value].tel : ''
                    formatedInfoVente.push(t('customer')+': '+clientName+clientMoreInfo)
                break;
                case 'created_at':
                    formatedInfoVente.push(ticketDate.toLocaleString())
                break;
                case 'prevu':
                    formatedInfoVente.push(t('estimatedDelivery')+': '+deliveryDate.toLocaleString())
                break;
                case 'section_collect':
                    formatedInfoVente.push(t('collectZone')+': '+currentTicket[value].name)
                break;
                default :
                    formatedInfoVente.push(currentTicket[value])
            }
        }
    })

    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage du nom de l'article 
        let arr = []
        let n = 0
        while(n<paramArticle.length){
            if(n<paramArticle.length){
                if(value.block_quantity>0){
                    arr.push((value.article.sku) ? value.article.sku+' '+value.article[paramArticle[n]] : value.article[paramArticle[n]])
                    
                    n++
                }
            }
            if(n==paramArticle.length){
                let newArr = arr.join("/@/")
                formatedParamArticle.push(newArr)
            }
        }
    })
    
    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage des infos prix des articles
        let arr = []
        if(value.block_quantity>0){
            arr.push(value.tgc+'%')
            arr.push(espace_mille(value.unitaire,digit)+devise)

            arr.push('x'+value.quantity)
            if(+value.remise>0){
                arr.push('-'+value.remise)
            }else{
                arr.push(value.remise)
            }
            arr.push(espace_mille(value.prix_remise,digit)+devise)
            if(value.promotion_intitule!=null) arr.push(value.promotion_intitule)
        }

        fomatedPrice.push(arr.join('/@/'))
    })

    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage des notes
        if(value.note!=null || value.ecotaxe!=null){
            //Si l'article à une éco participation, on l'affiche dans la note de l'article
            //La fonction replace permet de retirer toutes les balises html existante sur le string qu'on récupère
            let note = (value.note != null && value.note != '' && value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? t('dont')+' '+t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe),digit)+devise+' \n '+value.note.replace(/<[^>]*>|\n/g, '') : 
            (value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? t('dont')+' '+t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe),digit)+devise : 
            (value.note != null && value.note != '') ? value.note.replace(/<[^>]*>|\n/g, '') : ''

            formatedNoteArticle.push(note)
        }else{
            formatedNoteArticle.push('')
        }
    })
    
    paramTaxes.forEach(value=>{//Formatage des totaux
        let arr = []
        switch (value) {
            case 'ttc':
                arr.push('Total '+value.toUpperCase())
                arr.push(espace_mille(totalAchat,digit)+devise)
            break;
            case 'ht':
                arr.push('Total '+value.toUpperCase())
                if(tgcTotal>0){
                    arr.push(espace_mille(+totalAchat - +tgcTotal,digit)+devise)
                }else{
                    arr.push(espace_mille(+totalAchat)+devise,digit)
                }
            break;
        }

        formatedTotaux.push(arr.join('/@/'))
    })

    Object.entries(tgcForTicketPdf).forEach(([taxe,value])=>{//Formatage des taxes
        let arr = []
        if(value > 0){
            arr.push('Total '+taxe+'%')
            arr.push(espace_mille(value,digit)+devise)
        }
        
        if(arr.length!=0) formatedTaxes.push(arr.join('/@/'))
    })

    formatedTaxes.push('Total '+authParam.company.nomenclature_tgc+'/@/'+espace_mille(tgcTotal,digit)+devise)

    if(formatedTaxes.length==0){
        formatedTaxes.push('/@/')
    }

    if(currentTicket.ticket.paiement_records.length!=0){
        currentTicket.ticket.paiement_records.forEach((value)=>{//Formatage des règlements
            let arr = []

            if(modePaiementMustBeTranslate.includes(value.mode)){
                arr.push(t(value.mode))
            }else if(modePaiementFidelite.includes(value.mode)){
                arr.push(t('loyalty'))
            }else{//replace est utilisé ici pour supprimer les "" potentiels sur le nom du mode de paiement
                arr.push(value.mode.replace(/["]/g,'' ))
            }

            arr.push(espace_mille(value.montant,digit)+devise)

            if(value.rendu!=0 && value.mode=='espece'){
                arr.push(t('deposited')+' : '+espace_mille(value.verse,digit)+devise)
                arr.push(t('changeBack')+' : '+espace_mille(value.rendu,digit)+devise)
            }
            
            formatedReglement.push(arr.join('/@/'))
        })
    }else{
        formatedReglement.push('/@/'+0+devise)
    }

    let article = '"'+formatedParamArticle.join('/@!')+'"'
    let infoShop = '"'+formatedInfoShop.join('/@!')+'"'
    let price = '"'+fomatedPrice.join('/@!')+'"'   
    let infoVente = '"'+formatedInfoVente.join('/@!')+'"'
    let totaux = '"'+formatedTotaux.join('/@!')+'"'  
    let taxes = '"'+formatedTaxes.join('/@!')+'"'
    let reglement = '"'+formatedReglement.join('/@!')+'"' 
    let barCode = currentTicket.bar
    let msgBye = '"'+messageBye.join('/@!')+'"'
    let codeShop = (thisShop) ? '"'+thisShop.code+'"' : '" "'
    let idShop = (thisShop) ? '"'+thisShop.id+'"' : '" "'
    let host = '"'+peripheriqueParam.imprimante_ticket.host+'"'
    let conditionsRetourMsg = (thisShop && thisShop.infos_inferieur_ticket!=null) ? '"'+thisShop.infos_inferieur_ticket.replace(/[\n\r]/g,'</text-line></align><align mode="center"><text-line>')+'"'  : '" "'
    let verse = (currentTicket.ticket.paiement_records.length!=0) ? 'yes' : 'no'
    let articleNote = '"'+formatedNoteArticle.join('/@!')+'"'
    let globalNote = (currentTicket.note!=null) ? '"'+currentTicket.note.replace(/<[^>]*>|\n/g, '')+'"' : '""'
    globalNote = globalNote.replace(/[\n\r]/g,'</text-line></align><align mode="left"><text-line>')
    articleNote = articleNote.replace(/[\n\r]/g,'</text-line></align><align mode="left"><text-line>')
    let numeroCommande = (thisShop && thisShop.interface=='restauration') ? '"'+currentTicket.numero+'"' : '" "'


    if(peripheriqueParam && peripheriqueParam.imprimante_ticket && peripheriqueParam.imprimante_ticket.model=='epson_tm_h6000V'){
        const response  = await fetch('http://localhost/portcom/printOrder.php',{
            method: 'POST',
            body: JSON.stringify({
                item:article.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                price:price, 
                infoShop:infoShop.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                infoVente:infoVente.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                totaux:totaux, 
                taxes:taxes, 
                reglement:reglement.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                barCode:barCode, 
                conditionsRetour:conditionsRetourMsg.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                msgBye:msgBye.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                codeShop:codeShop,
                printMode:printMode, 
                host:host, 
                verse:verse, 
                articleNote:articleNote.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                globalNote:globalNote.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                numeroCommande:numeroCommande,
                idShop:idShop,
                tgc:authParam.company.nomenclature_tgc
            })
        }).then((response) => {
        }).catch((error) => {
        })
    }else{
        if(from=='historiqueVente'){
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }
    }
}

export const epson_tm_h6000VDevis = async (posid,currentTicket,t,devise,peripheriqueParam,from,authParam) =>{
    let paramArticle = ['name']
    let paramInfoVente = ['created_at','bar','rh','client','devis_id']
    let paramTaxes = ['ttc']

    let thisShop = authParam.sections.length!=0 && authParam.sections.find(value=>value.id==posid)
    let ticketDate = new Date(currentTicket.created_at)

    let formatedParamArticle = []
    let formatedInfoShop = (thisShop && thisShop.infos_superieur_ticket!=null) ? thisShop.infos_superieur_ticket : []
    let fomatedPrice = []
    let formatedInfoVente = []
    let formatedTotaux = []
    let formatedTaxes = []
    let formatedNoteArticle = []

    let messageBye = (thisShop) ? [thisShop.name,t('messageEndReceipt')] : ["",""]
    let validiteDevis = (thisShop) ? t('devisValidite')+': '+thisShop.maxusemoisproforma+' '+t('month').toLowerCase() : " "
    let digit = (authParam.company.advanceparams.hasOwnProperty('ventilation_espece') && authParam.company.advanceparams.ventilation_espece.piece.find(piece=> +piece < 1)) ? 2 : 0;

    //section calcule des totaux
    let totalAchat = 0
    let totaltgc = {}
    currentTicket.ticket.ticket_detail.map(item=>{
        totalAchat+=item.prix_remise
        if(item.tgc!=0){//on exécute pas cette action sur le taux 0
            if(totaltgc[item.tgc]){
                //si le taux de tgc existe deja on ajoute le pris du produit au total existant
                totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
            }else{
                //Si aucun taux existe on ajoute la taxe 
                totaltgc={...totaltgc,[item.tgc]:item.prix_remise}
            }
            
        }
        // if(item.article!=null){
        //     totaltgc={...totaltgc,[item.tgc]:totaltgc[item.tgc]+=item.prix_remise}
        // }
    })

    let tgcForTicketPdf={}
    Object.entries(totaltgc).forEach(([tgcName,montant])=>{
        //fonction qui calcule le montant de tgc à payer par type de tgc
        let coef = +tgcName / 100 + 1
        let totalAmountTgc = +montant - (Math.round(+montant / +coef))
        tgcForTicketPdf[tgcName] = totalAmountTgc
    })
    //on calcule le total de tgc payé sur le ticket
    let tgcTotal = 0 
    Object.entries(tgcForTicketPdf).map(([key,value])=> 
        tgcTotal+=value
    )

    //end section calcule des totaux

    paramInfoVente.forEach(value=>{//Formatage des infos de vente
        if(currentTicket[value] || currentTicket.ticket[value]){
            switch (value) {
                case 'rh':
                    formatedInfoVente.push(t('cashier')+': '+currentTicket.ticket[value].name+' '+currentTicket.ticket[value].firstname)
                break;
                case 'client':
                    let clientName = (currentTicket.ticket[value].name!=null && currentTicket.ticket[value].firstname!=null) ? currentTicket.ticket[value].name+' '+currentTicket.ticket[value].firstname : (currentTicket.ticket[value].name!=null) ? currentTicket.ticket[value].name : ''
                    let clientMoreInfo = (currentTicket.ticket[value].tel!=null) ? ' / '+currentTicket.ticket[value].tel : ''
                    formatedInfoVente.push(t('customer')+': '+clientName+clientMoreInfo)
                break;
                case 'created_at':
                    formatedInfoVente.push(ticketDate.toLocaleString())
                break;
                case 'devis_id':
                    formatedInfoVente.push(validiteDevis)
                break;
                default :
                    formatedInfoVente.push(currentTicket[value])
            }
        }
    })

    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage du nom de l'article 
        let arr = []
        let n = 0
        while(n<paramArticle.length){
            if(n<paramArticle.length){
                arr.push((value.article.sku) ? value.article.sku+' '+value.article[paramArticle[n]] : value.article[paramArticle[n]])
                n++
            }
            if(n==paramArticle.length){
                let newArr = arr.join("/@/")
                formatedParamArticle.push(newArr)
            }
        }
    })
    
    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage des infos prix des articles
        let arr = []
        
        arr.push(value.tgc+'%')
        arr.push(espace_mille(value.unitaire,digit)+devise)
        arr.push('x'+value.quantity)
        if(+value.remise>0){
            arr.push('-'+value.remise)
        }else{
            arr.push(value.remise)
        }
        arr.push(espace_mille(value.prix_remise,digit)+devise)
        if(value.promotion_intitule!=null) arr.push(value.promotion_intitule)

        fomatedPrice.push(arr.join('/@/'))
    })

    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage des notes
        if(value.note!=null || value.ecotaxe!=null){
            //Si l'article à une éco participation, on l'affiche dans la note de l'article
            //La fonction replace permet de retirer toutes les balises html existante sur le string qu'on récupère
            let note = (value.note != null && value.note != '' && value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? t('dont')+' '+t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe),digit)+devise+' \n '+value.note.replace(/<[^>]*>|\n/g, '') : 
            (value.ecotaxe != null && value.ecotaxe != '' && +value.ecotaxe > 0) ? t('dont')+' '+t('ecotaxe').toLowerCase()+': '+espace_mille(Math.round(value.ecotaxe),digit)+devise : 
            (value.note != null && value.note != '') ? value.note.replace(/<[^>]*>|\n/g, '') : ''

            formatedNoteArticle.push(note)
        }else{
            formatedNoteArticle.push('')
        }
    })
    
    paramTaxes.forEach(value=>{//Formatage des totaux
        let arr = []
        switch (value) {
            case 'ttc':
                arr.push('Total '+value.toUpperCase())
                arr.push(espace_mille(totalAchat,digit)+devise)
            break;
            case 'ht':
                arr.push('Total '+value.toUpperCase())
                if(tgcTotal>0){
                    arr.push(espace_mille(totalAchat - tgcTotal,digit)+devise)
                }else{
                    arr.push(espace_mille(totalAchat,digit)+devise)
                }
            break;
        }

        formatedTotaux.push(arr.join('/@/'))
    })

    Object.entries(tgcForTicketPdf).forEach(([taxe,value])=>{//Formatage des taxes
        let arr = []
        if(value > 0){
            arr.push('Total '+taxe+'%')
            arr.push(espace_mille(value,digit)+devise)
        }
        
        if(arr.length!=0) formatedTaxes.push(arr.join('/@/'))
    })

    formatedTaxes.push('Total '+authParam.company.nomenclature_tgc+'/@/'+espace_mille(tgcTotal,digit)+devise)

    if(formatedTaxes.length==0){
        formatedTaxes.push('/@/')
    }

    let article = '"'+formatedParamArticle.join('/@!')+'"'
    let infoShop = '"'+formatedInfoShop.join('/@!')+'"'
    let price = '"'+fomatedPrice.join('/@!')+'"'   
    let infoVente = '"'+formatedInfoVente.join('/@!')+'"'
    let totaux = '"'+formatedTotaux.join('/@!')+'"'  
    let taxes = '"'+formatedTaxes.join('/@!')+'"'
    let barCode = currentTicket.bar
    let msgBye = '"'+messageBye.join('/@!')+'"'
    let codeShop = (thisShop) ? '"'+thisShop.code+'"' : '" "'
    let idShop = (thisShop) ? '"'+thisShop.id+'"' : '" "'
    let host = '"'+peripheriqueParam.imprimante_ticket.host+'"'
    let articleNote = '"'+formatedNoteArticle.join('/@!')+'"'
    articleNote = articleNote.replace(/[\n\r]/g,'</text-line></align><align mode="left"><text-line>')
    let globalNote = (currentTicket.note!=null) ? '"'+currentTicket.note.replace(/<[^>]*>|\n/g, '')+'"' : '""'
    globalNote = globalNote.replace(/[\n\r]/g,'</text-line></align><align mode="left"><text-line>')


    if(peripheriqueParam && peripheriqueParam.imprimante_ticket && peripheriqueParam.imprimante_ticket.model=='epson_tm_h6000V'){
        const response  = await fetch('http://localhost/portcom/printDevis.php',{
            method: 'POST',
            body: JSON.stringify({
                item:article.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                price:price, 
                infoShop:infoShop.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                infoVente:infoVente.normalize("NFD").replace(/[\u0300-\u036f]/g, ""), 
                totaux:totaux, 
                taxes:taxes, 
                barCode:barCode, 
                msgBye:msgBye.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                codeShop:codeShop,
                host:host, 
                globalNote:globalNote.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                idShop:idShop,
                articleNote:articleNote.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                tgc:authParam.company.nomenclature_tgc
            })
        }).then((response) => {
        }).catch((error) => {
        })
    }else{
        if(from=='historiqueVente'){
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }
    }
}